import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";


export default function Scroll () {
    
    const {pathname} = useLocation()

    useEffect(()=>{

        document.getElementById("scroll")?.scrollTo({
            top:0,
            left:0,
        });

    }, [pathname])

    return null;
}

