import React, { useEffect, useState } from "react";


function getWindowDimensions() {
    const {innerHeight:height, innerWidth:width} = window
    return {width, height}
}


export default function useWindowSize () {
    
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    
    useEffect(()=>{
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)

    },[])

    return windowDimensions
}

