import React from "react";
import { useSearchParams } from "react-router-dom";
import searchIcon from "../resources/icons/search-260.svg"
import SearchIconPath from "../resources/icons/SearchIconPath";


function valueDisplay (n:number) {
    if (n) {
        return n.toString()
        // return n.toString().split('').join(' ')
    }
    return ''
}

function reverseDisplay (s:string) {
    if (s) {
        return parseInt(s)
        // return parseInt(s.split(' ').join(''))
    }
    return 0
}


export default function SearchBar () {

    const [searchParams, setSearchParams] = useSearchParams()

    const [value, setValue] = React.useState(parseInt(searchParams.get('id')||'')||0)

    React.useEffect(()=>{
        if (value>999999999999) {setValue(999999999999)}
    },[value])
    
    React.useEffect(()=>{
        setValue(parseInt(searchParams.get('id')||'')||0)
    },[searchParams])

    const handleClick = () => {
        const prms = value?{id:`${value}`}:undefined
        setSearchParams(new URLSearchParams(prms))
    }


    return(
        <div
            className="flex my-4 max-w-[50%] min-h-max mob:max-w-[80%]"
        >
            <input
                className="rounded-3xl py-2 px-8 text-6xl sm:text-3xl mob:text-xl mob:px-4"
                placeholder="Tracking ID"
                value={valueDisplay(value)}
                onChange={e=>{setValue(reverseDisplay(e.target.value))}}
                onKeyDown={e=>{if (e.key=='Enter') {handleClick()}}}
                inputMode="numeric"
            />
            <button
                className="-ml-[1.4em] min-w-fit mr-[0.4em]"
                // -ml-20 ml-4
                onClick={handleClick}
            >
                <svg className="w-[1em] h-[1em] align-middle overflow-hidden fill-black hover:fill-arkanic-popblue hover:h-[0.9em]" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <SearchIconPath />
                </svg>
            </button>
        </div>
    )
}

