import React from "react";
import { NavLink, useNavigate } from "react-router-dom";





export default function Error () {

    const navigate = useNavigate()

    return(
        <div className="
        error
        w-full h-full
        flex flex-col justify-evenly items-center
        text-9xl font-semibold
        ">
            Error
            <div className="
            flex
            w-full justify-evenly
            ">
                <button className={"errorLink"} onClick={()=>navigate(-1)}>Back</button>
                <NavLink className={"errorLink"} to={'/'}>Home</NavLink>
            </div>
        </div>
    )
}

