import React from "react";





export default function Contact () {
    return(
        <>
            <p className="mainTextHead my-36 mob:my-16">
                Contact Us
            </p>
            <p className="mainTextBody">

                {/* <p className="font-semibold">
                    Email
                </p>
                contact@arkanic.com
                <br />
                <br />
                <p className="font-semibold">
                    Phone
                </p>
                +91 6289112233
                <br />
                <br />
                <p className="font-semibold">
                    Instagram
                </p>
                @arkanic.greens
                <br />
                <br /> */}

                <p className="font-semibold">
                    Registered Office
                </p>
                Arkanic Private Limited
                <br />
                5<sup>th</sup> Floor
                <br />
                {/* 9B Wood Street */}
                9B Dr Martin Luther King Sarani
                <br />
                Kolkata 700016
                <br />
                West Bengal
                <br />
                India
            </p>
        </>
    )
}

