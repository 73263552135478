import React from "react";
import { NavLink, Outlet } from "react-router-dom";




export default function AboutSubNavbar () {
    return(
        <>
            <div className="
            subNavBar
            w-full
            flex flex-row flex-shrink-0
            justify-evenly items-center
            h-24 
            mob:h-28
            ">
                <NavLink className={({isActive}) => (isActive ? 'activeSubNavLink' : 'subNavLink')} to='./who'>Who We Are</NavLink>
                <NavLink className={({isActive}) => (isActive ? 'activeSubNavLink' : 'subNavLink')} to='./what'>What We Do</NavLink>
                <NavLink className={({isActive}) => (isActive ? 'activeSubNavLink' : 'subNavLink')} to='./why'>Why We Do It</NavLink>
                <NavLink className={({isActive}) => (isActive ? 'activeSubNavLink' : 'subNavLink')} to='./contact'>Contact</NavLink>
            </div>

            <div className="
            subNavContent scrollbar
            h-full w-full overflow-y-auto
            " id="scroll">
                <Outlet />
            </div>
        </>
    )
}

