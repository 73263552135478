import React from "react";
import { useSearchParams } from "react-router-dom";



function getDate (ts:any) {
    const d = new Date(ts).toDateString().split(' ')
    return `${d[2]}/${d[1]}/${d[3].slice(2)}`
}

function getTime (ts:any) {
    const t = new Date(ts).toTimeString().split(' ')[0]
    return t.slice(0,5)
}



function makeDisplayData (fetchedData:any) {
    return [
        `Your ${fetchedData[2]['species']} has been grown by Arkanic using CEA technology`,

        `Your ${fetchedData[2]['variety']} was planted in a batch of ${fetchedData[1][0]['count']}
        at ${getTime(fetchedData[1][0]['time'])} on ${getDate(fetchedData[1][0]['time'])}
        by ${fetchedData[1][0]['user']}`,
        
        ...fetchedData[1][1] ? [`
        It was hand chosen for premium quality
        at ${getTime(fetchedData[1][1]['time'])} on ${getDate(fetchedData[1][1]['time'])}
        by ${fetchedData[1][1]['user']}
        `] : [],
        
        `It was harvested in a select batch of ${fetchedData[0]['count']}
        at ${getTime(fetchedData[0]['time'])} on ${getDate(fetchedData[0]['time'])}
        by ${fetchedData[0]['user']}`,
    ]
}




export default function TrackingInfo () {

    const [searchParams] = useSearchParams()

    const [value, setValue] = React.useState(parseInt(searchParams.get('id')||'')||0)
    
    React.useEffect(()=>{
        setValue(parseInt(searchParams.get('id')||'')||0)
    },[searchParams])

    const [fetchedData, setFetchedData] = React.useState<any>(null)

    const [stateInfo, setStateInfo] = React.useState('')

    const [trackingInfo, setTrackingInfo] = React.useState<string[]>([])

    const sendRequest = (trackingId:number) => {

        fetch('https://track.arkanic.com/', {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'tracking_id': `${trackingId}`
            },
        })
        .then(e=>{
            // console.log(e)
            e.ok ? setStateInfo(`Showing info for tracking ID ${value}`) : setStateInfo(`Network Error\nPlease try again`)
            e.json().then(f => {setFetchedData(f)})
            // e.json().then(f => {console.log(f);setFetchedData(f)})
        })
        .catch(e=>{
            // console.error(e)
            setStateInfo(`Request Error\nPlease try again`)
        })

    }

    React.useEffect(()=>{
        if (value) {
            sendRequest(value)
            setStateInfo(`Loading info for tracking ID ${value}`)
        }
        else {
            setFetchedData(null)
            setStateInfo(`Please enter a valid tracking ID`)
        }
    },[value])

    React.useEffect(()=>{
        console.log(fetchedData)
        if (fetchedData) {
            if (fetchedData.length) {
                setTrackingInfo(makeDisplayData(fetchedData))
            }
            else {
                setTrackingInfo([`Invalid tracking ID`])
            }
        }
        else {
            setTrackingInfo([])
        }
    },[fetchedData])


    return(
        <>
            <div className="text-2xl mb-16 text-center mob:text-lg mob:mb-10">
                {stateInfo}
            </div>
            <div className="">
                <div className="
                    trackingBorder
                    text-5xl mob:text-3xl text-center mob:font-medium
                    p-8
                ">
                    {trackingInfo.map((i,j)=>{return <p>{i}{j<trackingInfo.length-1 && <><br/><br/></>}</p>})}
                </div>
            </div>
        </>
    )
}

