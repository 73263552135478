import React from "react";
import { NavLink } from "react-router-dom";





export default function About () {
    return(
        <div className="
        w-full h-full flex
        flex-row
        mob:flex-col
        justify-evenly items-center
        text-6xl text-center
        sm:text-4xl
        mob:text-4xl
        ">
            <NavLink className={'pageNavLink'} to='./who'>Who We Are</NavLink>
            <NavLink className={'pageNavLink'} to='./what'>What We Do</NavLink>
            <NavLink className={'pageNavLink'} to='./why'>Why We Do It</NavLink>
        </div>
    )
}

