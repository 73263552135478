import React from "react";




export default function Shop () {
    return(
        <div className="h-full overflow-y-auto scrollbar">
            <div className="pageBorder">
                <p className="mainTextHead">
                    Pure Food
                    <br />
                    <br />
                    Coming Soon
                </p>
                <p className="mainTextBody">
                    <p className="font-semibold text-4xl">
                        Lettuce
                    </p>
                    <br/>
                    Romaine <br/>
                    Locarno <br/>
                    Lollo Rosso <br/>
                    Lollo Bionda <br/>
                    Red Oak <br/>
                    Green Oak <br/>
                    Butterhead <br/>
                    Black Rose <br/>
                    Green Batavia <br/>
                    Red Batavia <br/>
                    Iceberg <br/>
                </p>
                <p className="mainTextBody">
                    <p className="font-semibold text-4xl">
                        Herbs
                    </p>
                    <br/>
                    Peppermint<br/>
                    Coriander<br/>
                    Rosemary<br/>
                    Thyme<br/>
                    Purple Basil<br/>
                    Italian Basil<br/>
                    Thai Basil<br/>
                    Greek Basil<br/>
                    Salad Arugula (Rocket Leaf)<br/>
                    Wild Arugula (Rocket Leaf)<br/>
                </p>
                <p className="mainTextBody">
                    <p className="font-semibold text-4xl">
                        Other
                    </p>
                    <br/>
                    Spinach<br/>
                    Bok Choy<br/>
                    Scarlet Globe Radish<br/>
                </p>
            </div>
        </div>
    )
}

