import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../resources/alogo2.png";
import qr from "../resources/qr.png"
import qrtrack from "../resources/qrtrack.png"



export default function Home () {
    return(
        <div className="
        
        h-full w-full
        grid grid-cols-2 grid-rows-1 items-center justify-items-center
        mob:grid-cols-1 mob:grid-rows-2
        ">
            {/* flex flex-row justify-evenly items-center
            mob:flex-col */}
            {/* text-9xl font-semibold text-slate-400 */}
            <div className="
            aspect-square max-h-full max-w-full p-[20%]
            mob:p-16
            ">
                {/* w-full h-full */}
                <NavLink to={'track'} className="">
                    <img src={qrtrack} className="
                    object-contain
                    "/>
                </NavLink>
            </div>
            
            <div className="
            aspect-square max-h-full max-w-full p-[20%]
            mob:p-16
            ">
                {/* w-full h-full */}
                <NavLink to={'about'} className="">
                    <img src={logo} className="
                    object-contain
                    "/>
                </NavLink>
            </div>
        </div>
    )
}

