import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";



export default function TrackRedirect () {
    
    const navigate = useNavigate()
    const {pathname} = useLocation()

    useEffect(()=>{
        navigate(`/track?id=${parseInt(pathname.split('/')[2])}`, {replace:true})
    },[])
    
    return null
}

